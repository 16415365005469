body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body,
#root,
#app-container {
    height: 100%;
}

#app-container {
    overflow: auto;
    background-color: #161823;
}

#app-container.mobile-display{
    overflow: hidden;
}

#navbar {
    height: 86px;
}

.mobile-display #navbar {
    height: 70px;
}

.mobile-display #navbar .navbar-logo {
    position: relative;
    right: 3px;
    top: 3px;
}

.mobile-navbar-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 20px;
    text-align: center;
}

.mobile-navbar {
    background-color: #282c34;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    padding: 0 10px;
}

.mobile-navbar .navbar-icon-button {
    font-size: 40px;
    padding: 5px;
}

.mobile-navbar .navbar-icon-button.selected {
    color: #de5c89 !important;
}

.app-view {
    height: calc(100% - 86px);
    margin-bottom: 0!important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@font-face {
    font-family: 'Reglisse fill';
    src: url('../fonts/Reglisse_Fill.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Reglisse fill", Roboto, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.clickable {
    cursor: pointer;
    transition: .25s;
}

.clickable.rounded {
    border-radius: 3px;
}

@media (min-width: 750px) {
    .clickable:hover {
        background-color: #ddd !important;
        color: black !important;
    }
    
    .clickable.selected:hover {
        color: #de5c89 !important;
    }
    
    rect.clickable:hover {
        fill: #ddd !important;
    }
    
    .clickable-dark:hover .Dropdown-placeholder {
        background-color: #aaa !important;
        color: white !important;
        cursor: pointer;
    }
    
    .clickable-dark:hover {
        background-color: #aaa !important;
        color: white !important;
        cursor: pointer;
    }
}

.bold {
    font-weight: bold;
}

.t18-btn {
    background-color: #de5c89 !important;
    font-family: "Reglisse fill", Roboto, sans-serif;
}

.t18-btn.lm {
    margin-left: 10px !important;
}

.t18-btn.black {
    background-color: black !important;
}

.t18-btn.grey {
    background-color: grey !important;
}

.theme-color {
    color: #de5c89 !important;
}

.pink-t18-bg {
    background-color: #de5c89 !important;
}

.hover-color:hover {
    color: #de5c89 !important;
}

.title-wrap {
    text-align: center;
}

.title-wrap h5, .title-wrap h4, .title-wrap h3, .title-wrap h2, .title-wrap h1 {
    display: inline-block;
}

#navbar-title {
    color: white;
    display: inline-block;
    font-size: 60px;
    line-height: 60px;
    vertical-align: top;
    font-family: "Reglisse fill", Roboto, sans-serif;
    margin-right: 30px;
}

.navbar-menu-option {
    color: white;
    display: inline-block;
    vertical-align: top;
    font-family: "Reglisse fill", Roboto, sans-serif;
    line-height: 60px;
    margin: 0 15px;
    padding: 0 20px;
    font-size: 40px;
    cursor: pointer;
}

@media (max-width: 1500px) {
    #navbar-title {
        font-size: 40px;
    }

    .navbar-menu-option {
        font-size: 30px;
    }
}

@media (max-width: 1150px) {
    #navbar-title {
        font-size: 32px;
    }

    .navbar-menu-option {
        font-size: 24px;
    }
}

@media (max-width: 1020px) {
    #navbar-title {
        font-size: 24px;
    }

    .navbar-menu-option {
        font-size: 16px;
    }
}

.blurred {
    backdrop-filter: blur(10px); /* Adjust blur level as needed */
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
}

.navbar-menu-option.selected {
    background-color: #de5c89;
    border-radius: 10px;
}

.loadingLayerWrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.loadingBox {
    background-color: white;
    padding: 2em;
}

.tool-tip {
    z-index: 10001 !important;
}

.reglisse {
    font-family: "Reglisse fill", Roboto, sans-serif;
}

.user-navbar-display {
    font-family: "Reglisse fill", Roboto, sans-serif;
    position: absolute;
    top: 0;
    right: 20px;
}

.mobile-display .user-navbar-display {
    position: initial;
    top: initial;
    right: initial;
}

.center-align {
    text-align: center;
}

.inline-block {
    display: inline-block;
}

.lean-overlay.alert-popup-overlay {
    z-index: 4500 !important;
}

.input-field input[type=text]:focus,
.input-field input[type=email]:focus,
.input-field input[type=password]:focus,
.input-field input[type=number]:focus {
    border-bottom: 1px solid #de5c89 !important;
    box-shadow: 0 1px 0 0 #de5c89 !important;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid #de5c89 !important;
    background-color: #de5c89 !important;
}

.input-field label{
    /* color: #de5c89 !important; */
    color: #9e9e9e!important;
}

.show-password-icon {
    position: absolute;
    right: 0;
    top: 10px;
    color: #838383;
    z-index: 2;
    font-size: 26px;
}

.t18-card {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* background-color: white; */
    background-color: rgb(35, 40, 57);
    border-radius: 15px;
    text-align: center;
    padding: 10px;
    overflow: hidden;
    position: relative;
}

.t18-card .clickable:hover {
    background-color: rgb(45, 50, 67)!important;
    color: white!important;
}

.pointer {
    cursor: pointer;
}

.t18-card .card-text {
    font-size: 20px;
    /* color: black; */
    color: white;
}

.t18-card.selected {
    border: 4px solid #de5c89;
}

.t18-card .card-action-toolbar {
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
}

.mobile-display .t18-card .card-action-toolbar {
    padding-top: 0;
    padding-right: 0;
}

.t18-card .card-action-toolbar.selected {
    padding-top: 0;
}

.mobile-display .t18-card .card-action-toolbar.selected {
    position: relative;
    bottom: 5px;
}

.t18-card .card-action-toolbar .icon-btn {
    font-size: 24px;
}

.mobile-display .t18-card .card-action-toolbar .icon-btn {
    font-size: 36px;
    margin: 0 10px;
    margin-bottom: 5px;
}

.card-name {
    height: 60px;
    overflow: hidden;
    word-break: break-word;
}

.mobile-display .modal {
    width: 90%;
    max-height: 80%;
}

.mobile-display .modal .modal-content{
    padding: 12px;
}

.large-modal  {
    max-height: 90% !important;
    top: 5% !important;
    width: 80% !important;
}

.utils-btn {
    font-size: 48px !important;
    line-height: 32px !important;
    padding: 0 10px !important;
}

.utils-btn.large {
    line-height: 48px !important;
}

.modal-content label {
    font-size: 20px !important;
}

/* @media (max-width: 1500px) {
    .media-selector-action-btn {
        font-size: 38px !important;
        line-height: 38px !important;
    }
}

@media (max-width: 1300px) {
    .media-selector-action-btn {
        font-size: 38px !important;
        line-height: 38px !important;
    }
} */

.media-selector {

}

.mobile-media-selector-action-toolbox {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 200;
}

.mobile-media-view-action-toolbox {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 200;
}

.mobile-media-selector-action-toolbox .icon-btn.media-selector-action-btn, .mobile-media-view-action-toolbox .icon-btn.media-view-action-btn {
    background-color: #de5c89;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    padding: 6px 10px 10px 6px!important;
    margin-top: 10px;
}

.mobile-media-selector-action-toolbox .icon-btn.media-selector-action-btn {
    color: white!important;
}

.folder-card .folder-card-icon-wrapper {
    height: 200px;
}

.folder-card .folder-card-icon {
    font-size: 160px;
    line-height: 160px;
}

.folder-card .folder-media-counter {
    position: absolute;
    text-align: center;
    left: calc(50% - 15px);
    color: white;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 50%;
}

.folder-card.mini {
    text-align: left;
    height: auto!important;
}

.folder-card.mini .folder-card-icon-wrapper {
    display: inline-block;
    height: auto;
    margin-right: 20px;
    position: relative;
}

.folder-card.mini .folder-card-icon {
    font-size: 60px;
    line-height: 60px;
}

.folder-card.mini .folder-media-counter {
    left: calc(100% - 25px);
    bottom: 5px;
}

.folder-card.mini .card-name {
    display: inline-block;
    height: auto;
    font-size: 32px;
}

@media (max-width: 1300px) {
    .folder-card .folder-card-icon {
        font-size: 120px;
        line-height: 120px;
    }
}

#chatWidget {
    z-index: 10000;
}

.h-100 {
    height: 100%;
    overflow: auto;
}

.lm {
    margin-left: 10px;
}

.lm-5 {
    margin-left: 5px;
}

.lm-30 {
    margin-left: 30px;
}

.rm {
    margin-right: 10px;
}

.rm-30 {
    margin-right: 30px;
}

.tm {
    margin-top: 10px;
}

.tm-50 {
    margin-top: 50px;
}

.bm {
    margin-bottom: 10px;
}

.tag-badge {
    display: inline-block;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 5px;
    font-size: 18px;
    background-color: white;
    color: black;
    text-align: center;
}

.tag-badge.selected {
    background-color: #de5c89;
    color: white;
}

.transition-height {
    transition: max-height .5s linear;
}

.view-select-btn {
    font-family: "Reglisse fill", Roboto, sans-serif;
    font-size: 28px !important;
    border-radius: 5px !important;
    background-color: grey !important;
    line-height: 40px !important;
    height: 40px !important;
}

.view-select-btn.selected {
    background-color: #de5c89 !important;
}

.url-map-input .input-field {
    margin: 0;
}

.progress {
    background-color: #ff80ab !important;
}

.progress .indeterminate {
    background-color: #de5c89 !important;
}

#navbar .navbar-icon-button {
    margin: 3px;
    font-size: 40px;
    margin-top: 18px;
    border-radius: 50%;
    padding: 5px;
}

.mobile-display #navbar .navbar-icon-button {
    margin-top: 5px;
}

#navbar .navbar-icon-button.selected {
    color: #de5c89 !important;
    border: 3px solid #de5c89;
    background-color: white;
}

div::-webkit-scrollbar-button {
    display: none;
}

div::-webkit-scrollbar-thumb {
    background-color: #de5c89;
    border-radius: 16px;
    border: 4px solid #f5f5f5;
}

div::-webkit-scrollbar, div::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 16px;
}

.mobile-display div::-webkit-scrollbar{
    display: none;
}

.profile-icon {
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.profile-section{
    border-bottom: 1px solid #e0e0e0;
    padding: 5px;
    position: relative;
}

.profile-section h5{
    margin: 0.8rem 0 0.8rem 0;
}

.profile-section .profile-item{
    display: inline-block;
    width: calc(100% - 50px);
}

.mobile-display .profile-section .profile-item{
    position: relative;
    bottom: 3px;
}

.profile-section .profile-edit-icon{
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 32px;
}

.mobile-display .profile-section .profile-edit-icon {
    top: 11px;
}

.mobile-display .profile-panel h5, .mobile-display .subscription-display-panel h5 {
    font-size: 20px;
    margin: 0.5rem 0 0.5rem 0;
}

.mobile-display #pay-app-view h5 {
    font-size: 20px;
    margin: 0.5rem 0 0.3rem 0;
}

.mobile-display #pay-app-view h4 {
    font-size: 24px;
    margin: 0.5rem 0 0.3rem 0;
}

.mobile-display #pay-app-view .input-field {
    margin: 0.5rem 0 0.3rem 0;
}

.payment-currency-table .btn {
    width: 62px;
}

.mobile-display .payment-currency-table .btn {
    margin: 2px;
}

.app-container .modal {
    max-height: 80%;
}

.fixed-modal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
}

#profile-picture-select-modal {
    transform: none!important;
}

#initial-loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: black;
}

#initial-loading-container {
    width: 50%;
    display: inline-block;
    background-color: black;
    border-radius: 20px;
    position: relative;
    top: 30%;
}

#initial-loading .logo {
    max-height: 300px;
    margin-bottom: 20px;
}

#initial-loading .progress{
    background-color: white!important;
}

@media (max-width: 750px) {

    #initial-loading-container {
        width: 80%;
        top: 20%;
    }

    #initial-loading .logo {
        max-width: 100%;
    }

}

.media-display-wrapper {
    height: 200px;
    text-align: center;
    position: relative;
}

.media-display-wrapper .video-preview-wrapper{
    position: relative;
    height: 100%;
}

.media-mini-display, .profile-mini-display {
    text-align: center;
    border-radius: 15px;
    height: 425px;
    overflow: hidden;
    background-color: rgb(35, 40, 57);
    color: white;
}

.profile-mini-display {
    padding: 10px;
    height: 360px;
}

.profile-mini-display .creator-name {
    font-size: 24px;
    margin: 10px;
    word-break: break-word;
}

.grid-display .media-mini-display:hover, .grid-display .profile-mini-display:hover {
    background-color: rgb(45, 50, 67);
}

.return-title {
    background-color: rgb(35, 40, 57);
    width: 100%;
    height: 60px;
    padding: 5px 10px;
    text-align: left;
}

.return-title.fixed {
    position: fixed;
    top: 0;
    z-index: 1000;
}

.return-title .return-title-content {
    display: inline-block;
    margin-left: 15px;
    font-size: 24px;
    height: 50px;
    width: calc(100% - 70px);
    vertical-align: top;
    color: white;
}

.mobile-display .return-title .return-title-content {
    margin-left: 5px;
}

.return-title .return-btn {
    color: #de5c89;
    font-size: 32px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
}

.mobile-display .return-title .return-btn {
    margin-right: 5px;
}

.mobile-media-header.fixed,
.creator-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.creator-page .creator-header, 
.mobile-media-header .creator-header, 
.media-mini-display .creator-header {
    height: 60px;
    padding: 10px 10px;
    text-align: left;
}

.creator-page .creator-header, 
.mobile-media-header .creator-header {
    /* margin: 0 15px;
    margin-right: 20px; */
    background-color: rgb(35, 40, 57);
    color: white;
    /* height: initial!important; */
}

.creator-page .creator-header .return-btn, 
.mobile-media-header .creator-header .return-btn {
    color: #de5c89;
    font-size: 32px;
    margin-left: 5px;
    margin-right: 10px;
}

.mobile-media-header.hide-picture .profile-picture {
    display: none!important;
}

.mobile-media-header.hide-picture .return-btn {
    position: relative;
    top: 10px;
}

.creator-page .creator-header .return-btn {
    margin-top: 11px;
}

.creator-page .creator-medias .grid-display {
    /* height:initial!important; */
}

.mobile-media-header .creator-header .creator-description {
    font-size: 16px;
    margin: 5px 10px;
    word-break: break-all;
}

.creator-page .creator-header .creator-name,
.mobile-media-header .creator-header .creator-name, 
.media-mini-display .creator-header .creator-name {
    display: inline-block;
    margin-left: 15px;
    font-size: 24px;
    line-height: 50px;
    vertical-align: top;
    position: relative;
    bottom: 5px;
}

.creator-page .creator-header .creator-name,
.mobile-media-header .creator-header .creator-name {
    bottom: 1px;
}

.grid-display .media-mini-display .creator-header .creator-name {
    bottom: 8px;
}

.mobile-media-header .profile-picture,
.media-mini-display .profile-picture{
    display: inline-block;
    height: 40px; 
    border-radius: 50%;
    overflow: hidden;
}

.profile-picture, .creator-header {
    position: relative;
}

.creator-header .profile-picture {
    margin-top: 3px;
    width: 40px;
}

.media-mini-display .creator-header .profile-picture {
    margin-top: initial;
}

.mobile-media-header .creator-header .profile-picture {
    position: relative;
    top: 3px;
}

.creator-header .profile-picture img {
    width: 100%;
    height: 100%;
}

.creator-header .creator-name {
    margin-top: 3px;
    
}

.mobile-display .creator-page {
    z-index: 300;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background-color: #161823;
}

.creator-page .creator-picture {
    margin: 10px;
}

.profile-picture .profile-action-icon {
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: white; 
    background-color: #de5c89;
    border-radius: 50%;
    padding: 5px
}

.profile-picture .profile-action-icon-tooltip {
    position: absolute;
    bottom: 3px;
    right: 3px;
}

.selected-media-tab .profile-picture .profile-action-icon {
    bottom: 9px;
}

.media-mini-display .media-display {
    /* background-color: white; */
}

.media-mini-display .media-display-wrapper {
    height: 300px;
    width: 100%;
    overflow: hidden;
}

.media-mini-display .media-display-wrapper .video-preview-wrapper {
    height: initial;
}

.media-mini-display .media-display-wrapper .video-preview-wrapper {
    height: 100%;
} 

.media-mini-display .media-display-wrapper img, 
.media-mini-display .media-display-wrapper video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
}

.media-mini-display .media-footer {
    font-size: 16px;
    padding-top: 10px;
}

.media-mini-display .media-footer .media-title{
    word-break: break-all;
    padding: 0 10px;
    overflow: hidden;
    max-height: 50px;
}

.selected-media-display {
    position: relative;
    padding: 0!important;
    /* padding: 40px!important; */
    /* margin: 20px; */
    height: calc(100% - 40px);
    border-radius: 15px;
    overflow: hidden;
}

.selected-media-display img, .selected-media-display video {
    border-radius: 15px;
    overflow: hidden;
}

.mobile-display .selected-media-display {
    /* padding: 15px 5px!important; */
    margin: 10px;
    height: auto;
    width: calc(100% - 20px)!important;
    float: none!important;
    /* margin-left: 10px!important; */
}

.selected-media-display .close-icon-btn {
    font-size: 42px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.mobile-display .selected-media-display .close-icon-btn {
    top: 10px;
    right: 10px;
}

.mobile-menu .menu-item {
    background-color: rgb(35, 40, 57);
    font-size: 24px;
    padding: 10px 20px;
    border-radius: 15px;
    margin: 10px 20px;
    position: relative;
}

.mobile-menu .menu-item i {
    font-size: 32px;
}

.mobile-menu .menu-item span {
    margin-left: 15px;
    position: relative;
    bottom: 3px;
}

.media-upload-file-name, .media-upload-file-type, .media-upload-file-action {
    padding: 5px;
}

.media-upload-file-name {
    word-break: break-word;
}

.media-upload-file-type {
    display: inline-block;
    width: 70%;
}

.media-upload-file-action {
    display: inline-block;
    width: 30%;
    text-align: center;
    position: relative;
    top: 10px;
}

.media-badges {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
}

.media-badges.shift-top {
    top: 10px;
}

.media-badge {
    display: inline-block;
    padding: 5px;
    height: 34px;
    border-radius: 5px;
    font-size: 16px;
    background-color: #de5c89;
    color: white;
    margin-left: 5px;
}

.media-badge.text-badge {
    position: relative;
    bottom: 6px;
    font-weight: bold;
}

.mobile-display .media-filter-tab.col {
    padding-top: 10px;
    padding-bottom: 10px;
}

.subscription-filter, .subscription-filter label {
    color: white;
}

.mobile-display .subscription-filter, .mobile-display .subscription-filter label {
    color: black;
}

.subscription-filter-items {
    margin: 0 10px;
    padding: 15px 25px;
    background-color: rgb(35, 40, 57);
    border-radius: 15px;
    font-weight: bold;
}

.mobile-display .subscription-filter-items {
    background-color: white;
}

.currency-badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #de5c89;
    color: white;
    font-size: 18px;
    margin: 5px;
    font-weight: bold;
}

.wallet-connect-deep-link-button {
    display: inline-block;
    height: 58px;
    border-radius: 15px;
    width: 100%;
    text-align: left;
    background-color: #de5c89;
    color: white;
    position: relative;
    margin-top: 10px;
}

.wallet-connect-deep-link-icon {
    height: 40px;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: 10px;
}

.wallet-connect-deep-link{
    color: white;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-left: 75px;
    padding-top: 5px;
    font-size: 32px;
    position: relative;
    z-index: 1000;
}

.mobile-display .selected-media-view {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    background-color: #161823;
    z-index: 200;
}

.mobile-display .selected-media-view.above {
    z-index: 400;
}