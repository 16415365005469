

.media-filter-tab .tag-badge {
    width: 44%;
    margin: 10px 3%;
    font-family: "Reglisse fill", Roboto, sans-serif;
    text-align: center;
}

.mobile-display .media-filter-tab {
    position: absolute;
    z-index: 1000;
    background-color: white;
    color: black;
    max-height: 70%;
    height: auto;
    width: calc(100% - 40px) !important;
    margin-left: 20px !important;
    border-radius: 15px;
}

.mobile-display .media-filter-tab h5,
.mobile-display .media-filter-tab input {
    color: black !important;
    margin: 5px !important;
}

.mobile-display .media-filter-tab .input-field {
    margin: 0 !important;
}

.media-grid {
    overflow-x: hidden !important;
}

.selected-media-tab .profile-picture {
    width: 80%;
}

.mobile-display .selected-media-tab .profile-picture {
    width: 100%;
}

.mobile-profile-view-wrapper {
    height: calc(100% + 15px);
    overflow-y: auto;
}

#welcome-modal p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 18px;
}

.mobile-display #welcome-modal p {
    font-size: 16px;
}